import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./style.module.css";
import Navbar from "./navbar";
import CarouselComp from "./carousal";
import { Box } from "@mui/material";
import GameSection from "./gameSection";
import WinnersComp from "./winners";
import AboutUsComp from "./aboutUs";
import BonusModal from "./bonusMOdal";

export default function Dashboard() {
  const [bonusPop, setBonusPop] = useState(
    JSON.parse(localStorage.getItem("bonus_modal"))
  );

  return (
    <main>
      <Box className={styles.mainContainer}>
        <Navbar />
        <CarouselComp />
        <GameSection />
        <WinnersComp />
        <AboutUsComp />
        {bonusPop && (
          <BonusModal
            openProp={bonusPop}
            handleClose={() => {
              localStorage.setItem("bonus_modal", false);
              setBonusPop(false);
            }}
          />
        )}
      </Box>
    </main>
  );
}
