import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function HowToPlayComp({
  open,
  handleClose,
  loading,
  minute,
  orderMin,
  orderSec,
  issues,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "80vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: "282px",
          },
        }}>
        <DialogTitle
          sx={{
            bgcolor: "#4c4cad",
            padding: "10px",
            color: "#fff",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "center",
          }}>
          How To Play
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "4px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography sx={{ fontSize: "14px" }} mb={0.5} mt={1}>
              {minute} minutes 1 issue, {orderMin ? `${orderMin} minutes` : ""}{" "}
              {orderSec} seconds to order, 15 seconds waiting for the draw. It
              opens all day. The total number of trade is {issues} issues.
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              If you spend 100 to trade, after deducting 2 service fee, your
              contract amount is 98:
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              1. select green: if the result shows 1,3,7,9 you will get (98*2)
              196;If the result shows 5, you will get (98*1.5) 147
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              2. select red: if the result shows 2,4,6,8 you will get (98*2)
              196;If the result shows 0, you will get (98*1.5) 147
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              3. select violet:if the result shows 0 or 5, you will get (98*4.5)
              441
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              4. Select number:if the result is the same as the number you
              selected, you will get (98*9) 882
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              5. Select big: if the result shows 5,6,7,8,9 you will get (98 * 2)
              196
            </Typography>
            <Typography sx={{ fontSize: "14px" }} mb={0.5}>
              6. Select small: if the result shows 0,1,2,3,4 you will get (98 *
              2) 196
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="outlined"
            sx={{
              // color: "#fff !important",
              textTransform: "capitalize !important",
              borderRadius: "20px !important",
              padding: "5px 40px !important",
              fontWeight: "700 !important",
            }}
            onClick={handleClose}
            disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
