import { Box, Stack, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./styles.module.css";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import { Form, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotSchema, LoginSchema } from "../../../schemas/auth/loginSchema";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import RFHDatePicker from "../../../components/RFH/RFHDatePicker";
import { useCaptcha } from "../../../utils/reactQuery/captcha";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaId, setCaptchaId] = useState("");
  const { captcha, refetch } = useCaptcha(captchaId);
  const [showPassword, setShowPassword] = useState(false);
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [seconds, setSeconds] = useState(1);
  const [resendOtp, setresendOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  useEffect(() => {
    let interval;
    console.log("seconds == ", seconds);

    if (seconds === 0 && otpSend && resendOtp) {
      setSeconds(60);
      setresendOtp(false);
    }

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds, otpSend, resendOtp]);

  const defaultValues = {
    mobile: "",
  };

  useEffect(() => {
    console.log("in useCall backÍ");
    if (captcha?.data?.data?.captcahId) {
      setCaptchaId(captcha?.data?.data?.captcahId);
    }
  }, [captcha]);

  const methods = useForm({
    resolver: yupResolver(ForgotSchema),
    defaultValues,
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password-user`, {
        mobile: data.mobile,
        otp: enteredOtp,
        new_password: data.new_password,
      })
      .then((res) => {
        console.log("response", res);
        //   localStorage.setItem("user", data.mobile);
        // localStorage.setItem("token", res.data.data.token);
        toast.success(res.data.message);
        navigate("/login");
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  const handleSendOtp = (data) => {
    console.log("i am from handle send otp", data);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/send-otp-user`, {
        mobile: methods.getValues("mobile"),
      })
      .then((res) => {
        console.log("response", res);
        if (res.data.message == "SUCCESS") {
          toast.success("OTP send successful");
          setOtpSend(true);
          setresendOtp(true);
        } else {
          toast.error("Request already exists");
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response.data.error);
      });
  };

  const handleCaptcha = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/verifyCaptcha`, {
        captcha_id: captcha?.data?.data?.captcahId,
        userAnswer: enteredCaptcha,
      })
      .then((res) => {
        console.log("verify captcha response", res);
        toast.success(res.data.message);
        setVerifiedCaptcha(true);
      })
      .catch((err) => {
        console.log("verify captcha error", err);
        toast.error(err.response.data.message);
      });
  };
  // /auth/verify-otp
  const handleOtpVerify = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/verify-otp`, {
        mobile: methods.getValues("mobile"),
        otp: enteredOtp,
      })
      .then((res) => {
        console.log("response", res);
        toast.success("OTP verified");
        setOtpVerified(true);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <Stack className={styles.mainContainer}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(otpSend ? onSubmit : handleSendOtp)}>
          <Box className={styles.form}>
            <Typography className={styles.loginTxt}>Forgot Password</Typography>
            <Box className={styles.textfieldCont}>
              <Typography className={styles.labels}>Phone Number</Typography>
              <RHFTextField type name="mobile" />
            </Box>

            <Typography className={styles.labels}>New Password</Typography>
            <Box className={styles.textfieldCont}>
              <RHFTextField
                type={showPassword ? "text" : "password"}
                name="new_password"
              />
              <Box
                className={styles.eyeCont}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}>
                {showPassword ? (
                  <VisibilityOff sx={{ color: "grey" }} />
                ) : (
                  <Visibility sx={{ color: "grey" }} />
                )}
              </Box>
            </Box>

            <Typography className={styles.labels}>Confirm Password</Typography>
            <Box className={styles.textfieldCont}>
              <RHFTextField
                type={showPassword ? "text" : "password"}
                name="passwordConfirmation"
              />
              <Box
                className={styles.eyeCont}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}>
                {showPassword ? (
                  <VisibilityOff sx={{ color: "grey" }} />
                ) : (
                  <Visibility sx={{ color: "grey" }} />
                )}
              </Box>
            </Box>
            <Stack direction="row" spacing={2}>
              <Typography className={styles.captchaCont}>
                {captcha?.data?.data?.question}
              </Typography>
              <RefreshIcon
                sx={{
                  backgroundColor: "#fff",
                  pointerEvents: verifiedCaptcha ? "none" : "",
                }}
                onClick={() => refetch()}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <TextField
                disabled={verifiedCaptcha}
                value={enteredCaptcha}
                onChange={(event) => setEnteredCaptcha(event.target.value)}
                variant="standard"
                placeholder="enter captcha"
                sx={{
                  marginTop: "8px",
                  "& .MuiInputBase-root::before": {
                    borderBottom: "1px solid #fff",
                    paddingLeft: "5px",
                  },
                  "& .MuiInputBase-input": {
                    backgroundColor: "#fff !important",
                    paddingLeft: "5px",
                  },
                  "& .Mui-focused": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiInput-root::after": {
                    borderBottom: "2px solid #fff !important",
                  },
                  "& .Mui-focused": {
                    backgroundColor: "#fff !important",
                  },
                }}
              />
              {verifiedCaptcha ? (
                <Stack direction="row" ml={1} mt="8px" alignItems="center">
                  <VerifiedIcon
                    sx={{
                      backgroundColor: "#fff",
                      color: "green",
                      padding: "2px",
                    }}
                  />
                  <Typography sx={{ backgroundColor: "#fff", padding: "2px" }}>
                    Verified
                  </Typography>
                </Stack>
              ) : (
                <LoadingButton
                  disabled={!enteredCaptcha}
                  onClick={handleCaptcha}
                  className={styles.captchaBtn}>
                  Verify Captcha
                </LoadingButton>
              )}
            </Stack>
            <Stack justifyItems="center" alignItems="center">
              {otpSend ? (
                <LoadingButton
                  disabled={seconds !== 0}
                  onClick={handleSendOtp}
                  className={styles.verifyOtpBtn}>
                  {seconds == 0
                    ? "Resend OTP"
                    : `Resend OTP in ${seconds} seconds`}
                </LoadingButton>
              ) : (
                <LoadingButton
                  // disabled={!enteredCaptcha}
                  // onClick={handleCaptcha}
                  type="submit"
                  className={styles.captchaBtn}>
                  Send OTP
                </LoadingButton>
              )}
            </Stack>
            <Stack direction="row">
              {otpSend && (
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="Enter OTP"
                  sx={{
                    marginTop: "8px",
                    "& .MuiInputBase-root::before": {
                      borderBottom: "1px solid #fff",
                      paddingLeft: "5px",
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "#fff !important",
                      paddingLeft: "5px",
                    },
                    "& .Mui-focused": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInput-root::after": {
                      borderBottom: "2px solid #fff !important",
                    },
                    "& .Mui-focused": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                  value={enteredOtp}
                  onChange={(event) => setEnteredOtp(event.target.value)}
                />
              )}

              {/* {otpSend && (
                <LoadingButton
                  // disabled={!enteredCaptcha}
                  onClick={handleOtpVerify}
                  // type="submit"
                  className={styles.captchaBtn}>
                  Verify OTP
                </LoadingButton>
              )} */}
            </Stack>
            {verifiedCaptcha && otpSend && (
              <Box>
                <LoadingButton
                  disabled={!enteredOtp}
                  loading={loading}
                  type="submit"
                  className={styles.btnLogin}>
                  Reset Password
                </LoadingButton>
              </Box>
            )}

            <Typography
              className={styles.gotoHome}
              onClick={() => navigate("/")}>
              Go To Home
            </Typography>
          </Box>
        </form>
      </FormProvider>
    </Stack>
  );
}
