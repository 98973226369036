import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import { useResponsive } from "../../../hooks/customUseResponsive";
import { useBonusQueryList } from "../../../utils/reactQuery/subAdmin/getBonusList";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

export default function BonusModal({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");
  const { list } = useBonusQueryList();
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
    overflow: "hidden", // To prevent content overflow outside the modal
  };

  const contentStyle = {
    maxHeight: "75vh", // Adjust this value as needed
    overflowY: "auto", // Enables vertical scrolling
  };

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={style}>
        <Stack className={styles.header} spacing={1}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "20px",
            }}
            onClick={handleClose}
          />
          <Typography className={styles.heading}>
            Extra first deposit bonus
          </Typography>
          <Typography className={styles.sunHead}>
            Each account can only receive rewards once
          </Typography>
        </Stack>
        <Box sx={contentStyle}>
          <Stack className={styles.formCont} spacing={2}>
            {list?.map((ele, idx) => (
              <Box className={styles.bonusItemCont} key={idx}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    First deposit{" "}
                    <span className={styles.depositAmount}>
                      {ele.first_deposit_amount}
                    </span>
                  </Typography>
                  <Typography className={styles.bonusAmount}>
                    +{ele.first_deposit_reward}
                  </Typography>
                </Stack>
                <Typography className={styles.description}>
                  Deposit {ele.first_deposit_amount} for the first time and you
                  will receive {ele.first_deposit_reward} bonus
                </Typography>
                <Stack justifyContent="end">
                  <Button
                    variant="outlined"
                    sx={{
                      color: " rgb(247, 185, 71)",
                      border: "1px solid  rgb(247, 185, 71)",
                      width: 100,
                      fontSize: "12px",
                      padding: "1px 15px",
                      alignSelf: "end",
                    }}
                    onClick={() => navigate("/deposit")}>
                    Deposit
                  </Button>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
