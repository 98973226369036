import { useQuery } from "react-query";
import { getBonusList } from "../../../services/subadmin/bonusList";

export function useBonusQueryList() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["bonusList"],
    queryFn: () => getBonusList(),
  });

    const list = data?.data?.data;

  return { isLoading, error, list, isError, refetch };
}
