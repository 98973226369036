import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import toast from "react-hot-toast";
import Api from "../../services/axios";
import AcceptPop from "./acceptpop/AcceptPop";

export default function ViewFund({
  openPop,
  handleClose,
  id,
  headind,
  transAmount,
  gameTypeId,
  period,
  category,
  refetch,
}) {
  // const isMobile = useResponsive("down", "sm");
  // const { reason, isLoading } = ViewMessageLeave(id);
  const [value, setValue] = useState(1);
  const [bankDetails, setBankDetails] = useState("");
  const [error, setError] = useState("");
  const [acceptPop, setAcceptPop] = useState(false);

  console.log("selected trans === ", id);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
    maxHeight: "90vh",
    overflowX: "auto",
  };

  const handleAccept = () => {
    setAcceptPop(true);
  };

  const handleReject = () => {
    Api.post("/admin/update-false-withdrawDeposit-request", {
      transaction_number: id,
    })
      .then((res) => {
        console.log("reject withdraw == ", res);
        toast.success(res.data.message);
        handleClose();
      })
      .catch((err) => {
        console.log("reject withdraw  == ", err);
        handleClose();
      });
  };

  useEffect(() => {
    Api.post("/admin/check-user-fund-availability", {
      transaction_number: id,
    })
      .then((res) => {
        console.log("userFund", res);
        setValue(res.data.data.user_fund);
        setBankDetails(res.data.data.user_bank_details);
      })
      .catch((err) => {
        console.log("userFund err", err);
        setError(err.response.data.error);
      });
  }, []);

  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>
            Check Fund Availability
          </Typography>
        </Stack>
        <Stack justifyContent="center" my={2} alignItems="center">
          {error ? (
            <Typography color={"red"} fontSize="20" fontWeight={700}>
              {error}
            </Typography>
          ) : value ? (
            <Box>
              <Typography>Transaction Amount: {transAmount}</Typography>
              <Typography>Available Fund: {value?.fund}</Typography>
              <Typography>Mobile Number: {value?.mobile_number}</Typography>
              <Typography>
                Account Number: {bankDetails?.account_number}
              </Typography>
              <Typography>Bank Name: {bankDetails?.bank_name}</Typography>
              <Typography>IFSC Code: {bankDetails?.ifsc_code}</Typography>
              <Typography>UPI id: {bankDetails?.upi_id}</Typography>
            </Box>
          ) : (
            ""
          )}
        </Stack>
        <Stack m={1} pl={2} justifyContent="center" direction="row">
          <Button
            className={styles.btnCloseClass}
            onClick={handleClose}
            variant="contained">
            Close
          </Button>
          <Button
            className={styles.btnRejectClass}
            onClick={handleReject}
            variant="contained">
            Reject
          </Button>
          <Button
            className={styles.btnAcceptClass}
            onClick={handleAccept}
            variant="contained"
            disabled={error}
            color="primary">
            Accept
          </Button>
        </Stack>
        {acceptPop && (
          <AcceptPop
            openProp={acceptPop}
            handleClose={() => setAcceptPop(false)}
            id={id}
            refetch={refetch}
          />
        )}
      </Box>
    </Modal>
  );
}
