import AdminComp from "../../pages/admin";
import ColorGameComp from "../../pages/colorGame";
import Dashboard from "../../pages/dashboard";
import DepositComp from "../../pages/deposit";
import ProfileComp from "../../pages/profile";
import WithdrawComp from "../../pages/withdraw";
import Private from "./private";

export const Pages={
    element: <Private/>,
    children:[{
        element: <Dashboard/>,
        path: '/dashboard',
    },{
        element: <ColorGameComp/>,
        path: '/color'
    },{
        element: <AdminComp/>,
        path: '/admin_dashboard'
    },{
        element: <WithdrawComp/>,
        path: '/withdraw'
    },{
        element: <ProfileComp/>,
        path: '/profile'
    },{
        element: <DepositComp/>,
        path: '/deposit'
    }]
}