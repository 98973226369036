import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import toast from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VipAmountSetting } from "../../../../schemas/admin/VipAmountSetting";
import Api from "../../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import { RHFCheckbox } from "../../../../components/RFH/RFHcheckbox";
import RHFTextField from "../../../../components/RFH/RFHtextfield";
import { LoadingButton } from "@mui/lab";

export default function EditAmountSetting({
  openPop,
  handleClose,
  id,
  headind,
  gameTypeId,
  period,
  category,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 680,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
    maxHeight: "90vh",
    overflowX: "auto",
  };

  const methods = useForm({
    resolver: yupResolver(VipAmountSetting),
    defaultValues: {
      mobile_number: id,
      refer_count: "",

      minimum_withdrawl_enabled: false,
      minimum_withdrawl: "",
      maximum_withdrawl_enabled: false,
      maximum_withdrawl: "",

      bet_commission_enabled: false,
      bet_commission: "",
      referral_lum_sum_amount_enabled: false,
      referral_lum_sum_amount: "",
      committed_referral_count_enabled: false,
      committed_referral_count: "",
    },
  });

  useEffect(() => {
    Api.post("/admin/get-user-vip-setting", {
      mobile_number: id,
    })
      .then((res) => {
        console.log("get user vip setting == ", res);
        methods.setValue("mobile_number", res.data.data.mobile_number);
        methods.setValue("refer_count", res.data.data.refer_count);
        methods.setValue(
          "minimum_withdrawl_enabled",
          res.data.data.minimum_withdrawl_enabled
        );
        methods.setValue("minimum_withdrawl", res.data.data.minimum_withdrawl);
        methods.setValue(
          "maximum_withdrawl_enabled",
          res.data.data.maximum_withdrawl_enabled
        );
        methods.setValue("maximum_withdrawl", res.data.data.maximum_withdrawl);
        methods.setValue(
          "bet_commission_enabled",
          res.data.data.bet_commission_enabled
        );
        methods.setValue("bet_commission", res.data.data.bet_commission);
        methods.setValue(
          "referral_lum_sum_amount_enabled",
          res.data.data.referral_lum_sum_amount_enabled
        );
        methods.setValue(
          "referral_lum_sum_amount",
          res.data.data.referral_lum_sum_amount
        );
        methods.setValue(
          "committed_referral_count_enabled",
          res.data.data.committed_referral_count_enabled
        );
        methods.setValue(
          "committed_referral_count",
          res.data.data.committed_referral_count
        );
      })
      .catch((err) => {
        console.log("get user vip sitting error == ", err);
      });
  });
  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    Api.post("/admin/add-vip-user-setting", formData)
      .then((res) => {
        console.log("add vip user setting == ", res);
        toast.success(res.data.message);
        handleClose()
      })
      .catch((err) => {
        console.log("add vip user setting error == ", err);
        toast.error(err.response.data.error);
      });
  };
  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                VIP Amount Setting
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Box width="100%">
                  <Typography className={styles.label}>
                    Mobile Number
                  </Typography>
                  <RHFTextField
                    name="mobile_number"
                    disabled
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
                <Box width="100%">
                  <Typography className={styles.label}>Refer Count</Typography>
                  <RHFTextField
                    name="refer_count"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Stack className={styles.itemCont} width="100%">
                  <RHFCheckbox name="minimum_withdrawl_enabled" />
                  <Typography className={styles.label}>
                    Enable minimum Withdraw
                  </Typography>
                </Stack>

                <Box width="100%">
                  <Typography className={styles.label}>
                    Minimum Withdraw
                  </Typography>
                  <RHFTextField
                    name="minimum_withdrawl"
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Stack width="100%" className={styles.itemCont}>
                  <RHFCheckbox name="maximum_withdrawl_enabled" />
                  <Typography className={styles.label}>
                    Enable Maximum Withdraw
                  </Typography>
                </Stack>

                <Box width="100%">
                  <Typography className={styles.label}>
                    Maximum Withdraw
                  </Typography>
                  <RHFTextField
                    name="maximum_withdrawl"
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Stack width="100%" className={styles.itemCont}>
                  <RHFCheckbox name="bet_commission_enabled" />
                  <Typography className={styles.label}>
                    Enable Bet Commission
                  </Typography>
                </Stack>

                <Box width="100%">
                  <Typography className={styles.label}>
                    Bet Commission
                  </Typography>
                  <RHFTextField
                    name="bet_commission"
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Stack width="100%" className={styles.itemCont}>
                  <RHFCheckbox name="referral_lum_sum_amount_enabled" />
                  <Typography className={styles.label}>
                    Referral Lum Sum Amount Enabled
                  </Typography>
                </Stack>

                <Box width="100%">
                  <Typography className={styles.label}>
                    Referral Lum Sum Amount
                  </Typography>
                  <RHFTextField
                    name="referral_lum_sum_amount"
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Box width="100%">
                  <Stack className={styles.itemCont}>
                    <RHFCheckbox name="committed_referral_count_enabled" />
                    <Typography className={styles.label} mb={0}>
                      Committed Referral Count Enabled
                    </Typography>
                  </Stack>
                </Box>

                <Box width="100%">
                  <Typography className={styles.label}>
                    Committed Referral Count
                  </Typography>
                  <RHFTextField
                    name="committed_referral_count"
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  loading={!openPop}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
