import * as Yup from 'yup';

export const AddVipSchema = Yup.object().shape({
    name: Yup.string().required("name is required").required("name is required"),
    mobile: Yup.string().required("mobile is required"),
    password: Yup.string().required("password is required"),
    parent_refer_id: Yup.string().required("referral is required"),
    date_of_birth: Yup.string().required("DOB is required"),
    status_active: Yup.boolean().required('status is required'),
    vip: Yup.boolean()
  });