import * as Yup from "yup";
const phoneRegExp = /^[6-9]\d{9}$/;
export const RegistrationSchema = Yup.object().shape({
  name: Yup.string().required("First name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    })
    .required("Last name is required"),
  password: Yup.string().required("Last name is required"),
  parent_refer_id: Yup.string(),
  date_of_birth: Yup.string().required("Last name is required"),
  status_active: Yup.boolean(),
});
