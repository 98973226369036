import { useQuery } from "react-query";
import { getGameHistory } from "../../../services/history/gameHistory";

export function useGameHistory({ gameId, limit, offset }) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["gameHistory", gameId, limit, offset],
    queryFn: () => getGameHistory({ gameId, limit, offset }),
  });

  const list = data?.data?.data;

  return { isLoading, error, list, isError, refetch };
}
