import { Box, Button, Paper } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import styles from './style.module.css'
import image1 from '../../../utils/images/image11.jpeg'
import image2 from '../../../utils/images/image22.jpeg'
import image3 from '../../../utils/images/image33.jpeg'

export default function CarouselComp() {

  return (
    <Box className={styles.cont}>
      <Carousel className={styles.imageCont} indicators={false}>
      <img src={image1} alt="image1" className={styles.image}/>
      <img src={image2} alt="image2" className={styles.image}/>
      <img src={image3} alt="image3" className={styles.image}/>
      </Carousel>
    </Box>
  );
}
