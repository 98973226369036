import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import toast from "react-hot-toast";
import Api from "../../../services/axios";
import { QueryClient, useQueryClient } from "react-query";

export default function BidComp({
  openPop,
  handleClose,
  id,
  headind,
  gameTypeId,
  period,
  category,
}) {
  // const isMobile = useResponsive("down", "sm");
  // const { reason, isLoading } = ViewMessageLeave(id);
  const [value, setValue] = useState(100);
  const [textfield, setTextfield] = useState(1);
  const queryCLient = useQueryClient();

  console.log("leave message");
  const phoneNumber = localStorage.getItem("user");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
    maxHeight: "90vh",
    overflowX: "auto",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBid = () => {
    Api.post(`${process.env.REACT_APP_BASE_URL}/color-prediction/placeBid`, {
      mobileNumber: phoneNumber,
      bidAmount: textfield * value,
      bidCategory: category,
      bidValue: headind,
      gamePeriod: period,
      gameTypeId: gameTypeId,
      gameId: 1,
    })
      .then((res) => {
        console.log("bid placed == ", res);
        toast.success(res.data.message);
        queryCLient.invalidateQueries({ queryKey: ["userFund"] });
        handleClose();
      })
      .catch((err) => {
        console.log("bid error == ", err);
        toast.error(err.response.data.error);
        handleClose();
      });
  };
  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>Select {headind}</Typography>
        </Stack>
        <Stack mt={1} pl={2} justifyContent="center">
          <Typography className={styles.contractMoney}>
            Contract Money
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: "40px", marginBottom: "16px" }}>
            <Tab
              label="x 1"
              value={1}
              sx={(theme) => ({
                minWidth: "50px",
                width: "50px",
                height: "40px",
                minHeight: "40px",
                padding: "0px",
                marginRight: "10px",
              })}
            />
            <Tab
              label="x 10"
              value={10}
              sx={(theme) => ({
                minWidth: "50px",
                width: "50px",
                padding: "0px",
                height: "40px",
                minHeight: "40px",
                marginRight: "10px",
              })}
            />
            <Tab
              label="x 100"
              value={100}
              sx={(theme) => ({
                minWidth: "50px",
                width: "50px",
                padding: "0px",
                height: "40px",
                minHeight: "40px",
                marginRight: "10px",
              })}
            />
            <Tab
              label="x 1000"
              value={1000}
              sx={(theme) => ({
                minWidth: "50px",
                width: "50px",
                padding: "0px",
                height: "40px",
                minHeight: "40px",
                marginRight: "10px",
              })}
            />
          </Tabs>

          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            mb={1.5}>
            <Typography fontSize={20} mr={2}>
              {value} X
            </Typography>
            <RemoveCircleOutlineIcon
              sx={{ color: "red" }}
              onClick={() =>
                setTextfield((prev) =>
                  parseInt(prev) <= 1 ? 1 : parseInt(prev) - 1
                )
              }
            />
            <TextField
              value={textfield}
              sx={{
                margin: "0px 4px",
              }}
              inputProps={{
                style: {
                  height: "30px",
                  padding: "0 14px",
                  width: "60px",
                },
              }}
              InputProps={{
                type: "number",
                sx: {
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                },
              }}
              onChange={(event) =>
                event.target.value < 0
                  ? setTextfield(0)
                  : setTextfield(event.target.value)
              }
              type="number"
            />
            <AddCircleOutlineIcon
              onClick={() => setTextfield((prev) => parseInt(prev) + 1)}
            />
          </Stack>
          <Typography textAlign="center" mb={1.5}>
            Total Contract Money : &#8377; {textfield * value}{" "}
          </Typography>
        </Stack>
        <Stack className={styles.btnCont}>
          <Button
            className={styles.btnCloseClass}
            onClick={handleClose}
            variant="contained">
            Close
          </Button>
          <Button
            className={styles.btnConfirmClass}
            onClick={handleBid}
            variant="contained"
            disabled={textfield * value <= 0}
            color="primary">
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
