import * as Yup from "yup";

export const VipAmountSetting = Yup.object().shape({
  mobile_number: Yup.string().required("field is required"),
  refer_count: Yup.string().required("field is required"),

  minimum_withdrawl_enabled: Yup.boolean().required("field is required"),
  minimum_withdrawl: Yup.string().required("field is required"),
  maximum_withdrawl_enabled: Yup.boolean().required("field is required"),
  maximum_withdrawl: Yup.string().required("field is required"),

  bet_commission_enabled: Yup.boolean().required("field is required"),
  bet_commission: Yup.string().required("field is required"),
  referral_lum_sum_amount_enabled: Yup.string().required("field is required"),
  referral_lum_sum_amount: Yup.string().required("field is required"),
  committed_referral_count_enabled: Yup.string().required("field is required"),
  committed_referral_count: Yup.string().required("field is required"),
});
