import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

import { Form, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";


import axios from "axios";
import toast from "react-hot-toast";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import RHFTextField from "../../../../components/RFH/RFHtextfield";
import { RegistrationSchema } from "../../../../schemas/auth/RegistrationSchema";
import RFHDatePicker from "../../../../components/RFH/RFHDatePicker";
import { AdminRegistrationSchema } from "../../../../schemas/admin/AdminRegistration";

export default function AdminRegistration() {
  const navigate = useNavigate();
  const [loading, setLoading]= useState(false);

  const methods = useForm({
    resolver: yupResolver(AdminRegistrationSchema),
    defaultValues: {role: "admin", status_active: true},
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    
    setLoading(true);
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/admin-register`,{...data}).then((res)=>{
      console.log('response', res);
      toast.success(res.data.message);
      navigate('/adminlogin')
    }).catch((err)=>{
      console.log("error", err)
      toast.error(err.response.data.error);
      setLoading(false);
    })
  };

  return (
    <Stack className={styles.mainContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className={styles.form}>
            <Typography className={styles.loginTxt}>Registration</Typography>
            <Box className={styles.textfieldCont}>
              <RHFTextField name="name" placeholder="User Name" />
            </Box>

            <Box className={styles.textfieldCont}>
              <RHFTextField name="mobile" placeholder="Phone Number" />
            </Box>

            <Box className={styles.textfieldCont}>
              <RHFTextField type="password" name="password" placeholder="Password" />
            </Box>
{/* 
            <Box className={styles.textfieldCont}>
              <RHFTextField name="parent_refer_id" placeholder="Referral code" />
            </Box> */}

            <Box className={styles.textfieldCont}>
              <RFHDatePicker name="date_of_birth"/>
            </Box>
            
            <Box>
              <LoadingButton loading={loading} type="submit" className={styles.btnLogin}>
                Sign Up
              </LoadingButton>
            </Box>

            <Typography className={styles.doNotAcc}>
            If you have an account? {" "}
              <span className={styles.signupText} onClick={()=>navigate('/login')}>Log In</span>
            </Typography>

            <Typography className={styles.gotoHome} onClick={()=>navigate('/')}>Go To Home</Typography>
          </Box>
        </form>
      </FormProvider>
    </Stack>
  );
}
