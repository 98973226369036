import { useQuery } from "react-query";
import { getCatpcha } from "../../services/catpcha";
import { getAdminBankDetailList } from "../../services/bankDetailList";

export function useBankDetailsList() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["adminBankDetailList"],
    queryFn: () => getAdminBankDetailList(),
  });

  const list = data?.data?.data;

  return { isLoading, error, list, isError, refetch };
}
