import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import { AddVipSchema } from "../../../../schemas/admin/AddVIP.js";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFCheckbox } from "../../../../components/RFH/RFHcheckbox.js";
import RFHDatePicker from "../../../../components/RFH/RFHDatePicker.js";
import Api from "../../../../services/axios.js";
import toast from "react-hot-toast";

export default function AddVipModal({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    status_active: true,
    vip: true,
    parent_refer_id: "admin",
  };
  const methods = useForm({
    resolver: yupResolver(AddVipSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    Api.post("/admin/register-vip", formData)
      .then((res) => {
        console.log("response vip registration", res);
        toast.success(res.data.message);
        handleClose();
      })
      .catch((err) => {
        console.log("VIP error == ", err);
        toast.error(err.response.data.error);
      });
  };
  console.log("errors == ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add VIP</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={3}>
                <Typography className={styles.label} pt={1}>
                  Status
                </Typography>
                <RHFCheckbox name="status_active" />
              </Stack>
              <Box>
                <Typography className={styles.label}>Name</Typography>
                <RHFTextField
                  name="name"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography className={styles.label}>Mobile Number</Typography>
                <RHFTextField
                  name="mobile"
                  InputProps={{
                    type: "number",
                    sx: {
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography className={styles.label}>Password</Typography>
                <RHFTextField
                  name="password"
                  type="password"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              {/* <Box>
                <Typography className={styles.label}>Referral Code</Typography>
                <RHFTextField
                  name="parent_refer_id"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box> */}
              <Box>
                <Typography className={styles.label}>Date of Birth</Typography>
                <RFHDatePicker name="date_of_birth" />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={!openProp}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
