import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AmountSetting } from "../../../schemas/admin/AmountSetting";
import { RHFCheckbox } from "../../../components/RFH/RFHcheckbox";
import Api from "../../../services/axios";
import toast from "react-hot-toast";

export default function AmountSettingComp() {
  const defaultValues = {
    minimum_deposit_enabled: false,
    minimum_deposit: "",
    maximum_deposit_enabled: false,
    maximum_deposit: "",
    minimum_withdrawl_enabled: false,
    minimum_withdrawl: "",
    maximum_withdrawl_enabled: false,
    maximum_withdrawl: "",
    bet_commission_enabled: false,
    bet_commission: "",
    withdraw_commission_enabled: false,
    withdraw_commission: "",
    referral_commission_enabled: false,
    referral_commission_percentage: "",
    referral_amount: "",
    reward_bet_amount_enabled: false,
    reward_bet_amount: "",
    reward_bonus_percentage_of_total_bet_amount: "",
    daily_login_bet_count_enabled: false,
    daily_login_bet_count: "",
    reward_daily_login_reward_percentage: "",
    children_add_bet_count_enabled: false,
    children_add_bet_count: "",
    children_add_reward: "",
    consecutive_winning_count_enabled: false,
    consecutive_winning_count: "",
    consecutive_winning_amount: "",
  };
  const methods = useForm({
    resolver: yupResolver(AmountSetting),
    defaultValues,
  });

  useEffect(() => {
    Api.get("/admin/get-all-amount-settings")
      .then((res) => {
        console.log("get admin amount setting == ", res.data.data[0]);
        let response = res.data.data[0];

        methods.setValue(
          "minimum_deposit_enabled",
          response.minimum_deposit_enabled
        );
        methods.setValue("minimum_deposit", response.minimum_deposit);
        methods.setValue(
          "maximum_deposit_enabled",
          response.maximum_deposit_enabled
        );
        methods.setValue("maximum_deposit", response.maximum_deposit);

        methods.setValue(
          "minimum_withdrawl_enabled",
          response.minimum_withdrawl_enabled
        );
        methods.setValue("minimum_withdrawl", response.minimum_withdrawl);

        methods.setValue(
          "maximum_withdrawl_enabled",
          response.maximum_withdrawl_enabled
        );
        methods.setValue("maximum_withdrawl", response.maximum_withdrawl);

        methods.setValue(
          "bet_commission_enabled",
          response.bet_commission_enabled
        );
        methods.setValue("bet_commission", response.bet_commission);

        methods.setValue(
          "withdraw_commission_enabled",
          response.withdraw_commission_enabled
        );
        methods.setValue("withdraw_commission", response.withdraw_commission);

        methods.setValue(
          "referral_commission_enabled",
          response.referral_commission_enabled
        );
        methods.setValue(
          "referral_commission_percentage",
          response.referral_commission_percentage
        );

        methods.setValue("referral_amount", response.referral_amount);
        methods.setValue(
          "reward_bet_amount_enabled",
          response.reward_bet_amount_enabled
        );

        methods.setValue("reward_bet_amount", response.reward_bet_amount);
        methods.setValue(
          "reward_bonus_percentage_of_total_bet_amount",
          response.reward_bonus_percentage_of_total_bet_amount
        );

        methods.setValue(
          "daily_login_bet_count_enabled",
          response.daily_login_bet_count_enabled
        );
        methods.setValue(
          "daily_login_bet_count",
          response.daily_login_bet_count
        );

        methods.setValue(
          "reward_daily_login_reward_percentage",
          response.reward_daily_login_reward_percentage
        );
        methods.setValue(
          "children_add_bet_count_enabled",
          response.children_add_bet_count_enabled
        );

        methods.setValue(
          "children_add_bet_count",
          response.children_add_bet_count
        );
        methods.setValue("children_add_reward", response.children_add_reward);

        methods.setValue(
          "consecutive_winning_count_enabled",
          response.consecutive_winning_count_enabled
        );
        methods.setValue(
          "consecutive_winning_count",
          response.consecutive_winning_count
        );

        methods.setValue(
          "consecutive_winning_amount",
          response.consecutive_winning_amount
        );
      })
      .catch((err) => {
        console.log("get admin amount setting error == ", err);
      });
  }, []);

  const onSubmit = (formData) => {
    console.log("form data", formData);
    Api.post("/admin/add-amount-setting-fields", formData)
      .then((res) => {
        console.log("update admin amount setting == ", res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("update admin amount setting error == ", err);
        toast.error("something went wrong");
      });
  };
  return (
    <Box className={styles.container}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="minimum_deposit_enabled" />
                <Typography className={styles.label}>
                  Enable minimum deposit
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Minimum deposit
                </Typography>
                <RHFTextField name="minimum_deposit" />
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="maximum_deposit_enabled" />
                <Typography className={styles.label}>
                  Enable maximum deposit
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Maximum deposit
                </Typography>
                <RHFTextField name="maximum_deposit" />
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="minimum_withdrawl_enabled" />
                <Typography className={styles.label}>
                  Minimum withdrawl enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Minimum Withdrawl
                </Typography>
                <RHFTextField name="minimum_withdrawl" />
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="maximum_withdrawl_enabled" />
                <Typography className={styles.label}>
                  Maximum withdrawl enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Maximum Withdrawl
                </Typography>
                <RHFTextField name="maximum_withdrawl" />
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="bet_commission_enabled" />
                <Typography className={styles.label}>
                  Bet Commission enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>Bet commission</Typography>
                <RHFTextField name="bet_commission" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="withdraw_commission_enabled" />
                <Typography className={styles.label}>
                  Withdraw Commission Enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Withdraw Commission
                </Typography>
                <RHFTextField name="withdraw_commission" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="referral_commission_enabled" />
                <Typography className={styles.label}>
                  Referral Commission Enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Referral Commission Percentage
                </Typography>
                <RHFTextField name="referral_commission_percentage" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Referral Amount
                </Typography>
                <RHFTextField name="referral_amount" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="reward_bet_amount_enabled" />
                <Typography className={styles.label}>
                  Reward Bet Amount Enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Reward Bet Amount
                </Typography>
                <RHFTextField name="reward_bet_amount" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Reward bonus percentage of total bet Amount
                </Typography>
                <RHFTextField name="reward_bonus_percentage_of_total_bet_amount" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="daily_login_bet_count_enabled" />
                <Typography className={styles.label}>
                  Daily login bet count enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Daily login bet count
                </Typography>
                <RHFTextField name="daily_login_bet_count" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Reward Daily Login Reward Percentage
                </Typography>
                <RHFTextField name="reward_daily_login_reward_percentage" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="children_add_bet_count_enabled" />
                <Typography className={styles.label}>
                  Children Add Bet Count Enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Children add bet count
                </Typography>
                <RHFTextField name="children_add_bet_count" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Children Add Reward
                </Typography>
                <RHFTextField name="children_add_reward" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <RHFCheckbox name="consecutive_winning_count_enabled" />
                <Typography className={styles.label}>
                  Consecutive winning Count Enabled
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Consecutive winning count
                </Typography>
                <RHFTextField name="consecutive_winning_count" />
              </Stack>
            </Grid>

            <Grid item lg={4}>
              <Stack className={styles.itemCont}>
                <Typography className={styles.label}>
                  Consecutive winning amount
                </Typography>
                <RHFTextField name="consecutive_winning_amount" />
              </Stack>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
}
