export const DateFormate =(str)=>{
    let temp = str.split('-');
    
    function addZero(num){
        return num<10 ? `0${num}` : num
    }

    // console.log("date recive = ", str)
    // console.log("date formated = ", temp)
    
    return `${temp[2].slice(0,2)}/${temp[1]}/${temp[0]}`
}