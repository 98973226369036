import { useQuery } from "react-query";
import { getUserFund } from "../../../services/colorGame/userFund";

export function useUserFund() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["userFund"],
    queryFn: () => getUserFund(),
  });

  const userFund = data?.data?.data;

  return { isLoading, error, userFund, isError, refetch };
}
