
import AdminLogin from "../../pages/auth/admin/login";
import AdminRegistration from "../../pages/auth/admin/registration";
import ForgotPassword from "../../pages/auth/forgot_password";
import Login from "../../pages/auth/login";
import Registration from "../../pages/auth/registration";
import ResetPassword from "../../pages/auth/resetPassword";
import Dashboard from "../../pages/dashboard";
import Protected from "./protected";

export const Auth = {
        element: <Protected/>,
        children:[
            {
                element: <Login/>,
                path: '/login'
            },{
                element: <Registration/>,
                path: '/registration'
            },{
                element: <Dashboard/>,
                path: '/'
            },{
                element: <AdminLogin/>,
                path: '/adminlogin'
            },{
                element: <AdminRegistration/>,
                path: '/adminregistration'
            },{
                element: <ForgotPassword/>,
                path: "/forgotpassword"
            },{
                element: <ResetPassword/>,
                path: "/resetpassword"
            }
        ]
}