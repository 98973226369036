import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import winersTemp from "../../../utils/images/winners-template.png";
import winnerBack from "../../../utils/images/winnerBackground.png";
import axios from "axios";

export default function WinnersComp() {
  const temp = useRef("");
  const [state, setState] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user/getTopWinners?winner_count=5`
      )
      .then((res) => {
        console.log("response of winner == ", res);
        setState(res.data.data);
      })
      .catch((err) => {
        console.log("error of winner == ", err);
      });
  }, []);

  const [winners, setWinners] = useState([
    {
      name: "PedeVenenatis.ppt",
      price: "$6882.25",
      gameName: "aely0",
      color: "#36d080",
      image:
        "https://robohash.org/repudiandaecorrupticorporis.png?size=50x50&set=set1",
    },
    {
      name: "NullaIntegerPede.avi",
      price: "$3673.34",
      gameName: "aclawley1",
      color: "#7fc66b",
      image: "https://robohash.org/ipsamquaeratminus.png?size=50x50&set=set1",
    },
    {
      name: "Augue.doc",
      price: "$2752.55",
      gameName: "acrisall2",
      color: "#793e88",
      image:
        "https://robohash.org/minimaaccusamusquibusdam.png?size=50x50&set=set1",
    },
    {
      name: "SedTristiqueIn.avi",
      price: "$3115.59",
      gameName: "sadamovitz3",
      color: "#2fe893",
      image: "https://robohash.org/oditculpasint.png?size=50x50&set=set1",
    },
    {
      name: "LaciniaEgetTincidunt.txt",
      price: "$4267.03",
      gameName: "rheinrich4",
      color: "#0f33a5",
      image: "https://robohash.org/facilisvelitut.png?size=50x50&set=set1",
    },
    {
      name: "Mi.jpeg",
      price: "$8410.27",
      gameName: "dwaything5",
      color: "#8107e4",
      image: "https://robohash.org/temporaetminus.png?size=50x50&set=set1",
    },
    {
      name: "Id.mp3",
      price: "$4790.87",
      gameName: "bfremantle6",
      color: "#44ca52",
      image:
        "https://robohash.org/illumperspiciatisvoluptatum.png?size=50x50&set=set1",
    },
    {
      name: "CondimentumIdLuctus.mpeg",
      price: "$3290.76",
      gameName: "boconnel7",
      color: "#388590",
      image: "https://robohash.org/repudiandaesequiet.png?size=50x50&set=set1",
    },
    {
      name: "Faucibus.ppt",
      price: "$2788.24",
      gameName: "asevior8",
      color: "#9b7675",
      image: "https://robohash.org/etconsequaturqui.png?size=50x50&set=set1",
    },
    {
      name: "InLeoMaecenas.png",
      price: "$9919.55",
      gameName: "peyrl9",
      color: "#c79409",
      image:
        "https://robohash.org/ipsumrerumreprehenderit.png?size=50x50&set=set1",
    },
    {
      name: "Eleifend.jpeg",
      price: "$6281.43",
      gameName: "eaucocka",
      color: "#c7b0b4",
      image: "https://robohash.org/atveritatisadipisci.png?size=50x50&set=set1",
    },
    {
      name: "Vel.mp3",
      price: "$7362.39",
      gameName: "rrushtonb",
      color: "#304d9a",
      image: "https://robohash.org/utimpeditqui.png?size=50x50&set=set1",
    },
    {
      name: "UltricesMattisOdio.doc",
      price: "$3277.82",
      gameName: "losullivanc",
      color: "#cad64b",
      image: "https://robohash.org/architectoquiacum.png?size=50x50&set=set1",
    },
    {
      name: "MagnaAcConsequat.mpeg",
      price: "$4006.07",
      gameName: "msindled",
      color: "#32e48a",
      image:
        "https://robohash.org/consequaturestaccusamus.png?size=50x50&set=set1",
    },
    {
      name: "Vivamus.mp3",
      price: "$4586.88",
      gameName: "ctulloche",
      color: "#fb7ff7",
      image: "https://robohash.org/solutaeiusvel.png?size=50x50&set=set1",
    },
    {
      name: "Duis.xls",
      price: "$2631.95",
      gameName: "nsedgmondf",
      color: "#d2ed9f",
      image: "https://robohash.org/maximevitaeofficia.png?size=50x50&set=set1",
    },
    {
      name: "ElitProinInterdum.avi",
      price: "$9683.86",
      gameName: "ipallisterg",
      color: "#5d8c30",
      image: "https://robohash.org/etsuntvoluptatem.png?size=50x50&set=set1",
    },
    {
      name: "BlanditNamNulla.xls",
      price: "$1230.80",
      gameName: "gbenedettih",
      color: "#f1290b",
      image: "https://robohash.org/eiusimpeditdeserunt.png?size=50x50&set=set1",
    },
    {
      name: "AdipiscingElit.ppt",
      price: "$1332.81",
      gameName: "coliphardi",
      color: "#9ee269",
      image: "https://robohash.org/etsitvoluptas.png?size=50x50&set=set1",
    },
    {
      name: "AtDiam.xls",
      price: "$7974.84",
      gameName: "scristofaninij",
      color: "#d4494d",
      image: "https://robohash.org/sitfugadolorum.png?size=50x50&set=set1",
    },
  ]);

  // setInterval(()=>{
  //     let helper = winners
  //     temp.current = helper.pop();
  //     setWinners([temp.current,...helper])

  //     console.log(winners,temp)
  // },2000)
  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.greenTab} />
        <Stack direction="row" className={styles.headerContainer}>
          <Typography className={styles.tableHead}>
            Winning information
          </Typography>
        </Stack>
      </Box>

      <Stack className={styles.tempCont}>
        <img src={winnerBack} className={""} />

        <img src={winersTemp} className={styles.winnerImage} />
      </Stack>

      <Box>
        {winners
          .filter((ele, idx) => idx < 5)
          .map((ele, idx) => (
            <Stack className={styles.cardCont}>
              <Avatar
                sx={{ backgroundColor: ele.color }}
                src={ele.image}></Avatar>
              <Typography className={styles.gameName} ml={1}>
                {state[idx]?.name.slice(0, 2) +
                  "***" +
                  state[idx]?.name.slice(4, 10)}
              </Typography>
              <Box>
                <Typography className={styles.price}>
                  Receive <span>&#8377;</span>
                  {state[idx]?.total_winning_amount.replace("$", "")}
                </Typography>
                <Typography className={styles.amountTxt}>
                  winning amount
                </Typography>
              </Box>
            </Stack>
          ))}
      </Box>
    </>
  );
}
