import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { DateFormate } from "../../../utils/helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useSubAdminQuery } from "../../../utils/reactQuery/subAdmin/getSubAdminList";
import AddBonusModal from "./addBonus";
import { useBonusQueryList } from "../../../utils/reactQuery/subAdmin/getBonusList";

export default function BonusComp() {
  const [addBonusModal, setAddBonusModal] = useState(false);
  const { list } = useBonusQueryList();

  const columns = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "first_deposit_reward",
      headerName: "First Deposit Reward",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "first_deposit_amount",
      headerName: "First Deposit Amount",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  return (
    <Box>
      <Stack direction="row" className={styles.headerContainer}>
        <Typography className={styles.tableHead}>Bonus</Typography>
        <Button
          className={styles.btnClass}
          onClick={() => setAddBonusModal(true)}
          variant="contained"
          color="primary">
          Add Bonus
        </Button>
      </Stack>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Bonus list"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns}
            initialRows={
              list
                ? list.map((ele, idx) => ({
                    id: idx,
                    ...ele,
                  }))
                : []
            }
          />
        </Box>
      </Box>
      {addBonusModal && (
        <AddBonusModal
          openProp={addBonusModal}
          handleClose={() => setAddBonusModal(false)}
        />
      )}
    </Box>
  );
}
