// import * as Yup from 'yup';

// export const AdminBankDetailsSchema = Yup.object().shape({
//     status: Yup.boolean(),
//     account_number: Yup.string(),
//     bank_name: Yup.string(),
//     ifsc_code: Yup.string(),
//     actual_name: Yup.string(),
//     upi_id: Yup.string(),
//     qr_code: Yup.string()
// });
import * as Yup from "yup";

export const AdminBankDetailsSchema = Yup.object().shape({
  status: Yup.boolean(),
  account_number: Yup.string(),
  bank_name: Yup.string(),
  ifsc_code: Yup.string(),
  actual_name: Yup.string(),
  upi_id: Yup.string(),
  qr_code: Yup.mixed().test(
    "fileSize",
    "File size should be less than 3MB",
    (value) => {
      if (!value) return true; // If no file is uploaded
      return value.size <= 2 * 1024 * 1024; // 3MB size limit
    }
  ),
});
