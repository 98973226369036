import axios from "axios";
import React, { useEffect, useState } from "react";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { Box, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGameHistory } from "../../../utils/reactQuery/history/gameHistory";

export default function HistoryComp({ gameId }) {
  const [limitState, setLimit] = useState(10);
  const [offsetState, setOfffset] = useState(0);
  const [history, setHistory] = useState([{}]);
  const { list } = useGameHistory({
    gameId,
    limit: limitState,
    offset: offsetState,
  });

  console.log("useGameHistory ==", list);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_BASE_URL
  //       }/color-prediction/getGameHistory?gameTypeId=${gameId}&limit=${limitState}&offset=${
  //         offsetState * 10
  //       }`
  //     )
  //     .then((res) => {
  //       console.log("history response == ", res);
  //       setHistory(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log("history component == ", err);
  //     });
  // }, [limitState, offsetState]);

  const columns = [
    {
      field: "col1",
      headerName: "Period",
      //   minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Number",
      //   minWidth: 127,
      flex: 0.5,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Size",
      //   minWidth: 126,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Color",
      //   minWidth: 120,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => {
        console.log(" ==== ", value?.value?.split("_"));
        return (
          <Stack
            key={value?.value}
            direction={"row"}
            spacing={1}
            justifyContent="center"
            height="100%"
            alignItems="center">
            {value?.value?.split("_").map((ele) => (
              <Box
                key={value?.value}
                style={{
                  backgroundColor: ele.toLowerCase(),
                  width: "15px",
                  height: "15px",
                  borderRadius: "10px",
                }}></Box>
            ))}
          </Stack>
        );
      },
    },
  ];
  return (
    <Box mt={1}>
      {/* {history.map((ele) => (
        <h1>{ele.color}</h1>
      ))} */}
      <DataGridComp
        columns={columns}
        borderEnable={false}
        initialRows={
          list
            ? list.map((ele, idx) => {
                return {
                  id: idx,
                  col1: ele.gamePeriod,
                  col2: ele.number,
                  col3: ele.size,
                  col4: ele.color,
                };
              })
            : []
        }
        footer={true}
      />
      <Stack direction="row" sx={{ width: "100%", justifyContent: "center" }}>
        <ArrowBackIcon
          onClick={() => setOfffset((prev) => prev - 1)}
          sx={{ visibility: offsetState <= 0 ? "hidden" : "visible" }}
        />
        <Typography>{offsetState}</Typography>
        <ArrowForwardIcon onClick={() => setOfffset((prev) => prev + 1)} />
      </Stack>
    </Box>
  );
}
