import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import { AddVipSchema } from "../../../../schemas/admin/AddVIP.js";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFCheckbox } from "../../../../components/RFH/RFHcheckbox.js";
import RFHDatePicker from "../../../../components/RFH/RFHDatePicker.js";
import Api from "../../../../services/axios.js";
import toast from "react-hot-toast";
import { AddSubAdminSchema } from "../../../../schemas/admin/AddSubAdmin.js";
import { updateSubAdminSchema } from "../../../../schemas/admin/updateSubAdmin.js";
import { QueryClient, useQueryClient } from "react-query";
import { changeSubadminPassSchema } from "../../../../schemas/admin/ChnageSubadminPass.js";

export default function ChangeSubAdminPassModal({
  openProp,
  handleClose,
  mblNo,
  status,
}) {
  const isMobile = useResponsive("down", "sm");
  const queryCient = useQueryClient();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };
  console.log("props mobile number, status", mblNo, status);

  const defaultValues = {
    mobile: mblNo,
  };
  const methods = useForm({
    resolver: yupResolver(changeSubadminPassSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    Api.post("/admin/change-password-admin-by-subadmin", formData)
      .then((res) => {
        console.log("response of update password", res);
        toast.success(res.data.message);
        handleClose();
      })
      .catch((err) => {
        console.log("update password error == ", err);
        toast.error(err.response.data.error);
      });
  };
  console.log("errors == ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Update Sub Admin Password
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>Mobile Number</Typography>
                <RHFTextField
                  name="mobile"
                  disabled
                  InputProps={{
                    type: "number",
                    sx: {
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography className={styles.label}>Password</Typography>
                <RHFTextField
                  name="new_password"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={!openProp}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Update
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
