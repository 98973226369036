import axios from "axios";
import Api from "../axios";

export const getMyHistory = async ({ mobileNumber, gameId, limit, offset }) => {
  return await Api.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/color-prediction/getUserGameHistory?gameTypeId=${gameId}&mobileNumber=${mobileNumber}&limit=${limit}&offset=${
      offset * 10
    }`
  );
};
