import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import colorPredict from "../../../utils/images/colourPredict.jpg";
import aviatorPred from "../../../utils/images/aviator-betting-game.jpg";
import { useNavigate } from "react-router";

export default function GameSection() {
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.greenTab} />
        <Stack direction="row" className={styles.headerContainer}>
          <Typography className={styles.tableHead}>Games</Typography>
        </Stack>
      </Box>

      <Card className={styles.cardSection} onClick={()=>navigate('/color')}>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={colorPredict}
          alt="Live from space album cover"
        />
        <CardContent className={styles.cardContent}>
          <Typography className={styles.cardHead}>Color Trading</Typography>
          <Typography className={styles.cardText}>
            You can place bets on various
          </Typography>
          <Typography className={styles.cardText}>
          colors. If luck is on your side
          </Typography>
          <Typography className={styles.cardText}>
            you will win a huge amount.
          </Typography>
        </CardContent>
      </Card>

      {/* <Card className={styles.cardSection}>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={aviatorPred}
          alt="Live from space album cover"
        />
        <CardContent className={styles.cardContent}>
          <Typography className={styles.cardHead}>Aviator</Typography>
          <Typography className={styles.cardText}>
            Lorem Ipsum is dummy text
          </Typography>
          <Typography className={styles.cardText}>
            of the printing and
          </Typography>
          <Typography className={styles.cardText}>
            typesetting industry.
          </Typography>
        </CardContent>
      </Card> */}
    </>
  );
}
