import { useQuery } from "react-query";
import { getMyHistory } from "../../../services/history/myHistory";

export function useMyHistory({ mobileNumber, gameId, limit, offset }) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["myHistory", mobileNumber, gameId, limit, offset],
    queryFn: () => getMyHistory({ mobileNumber, gameId, limit, offset }),
  });

  const list = data?.data?.data;

  return { isLoading, error, list, isError, refetch };
}
