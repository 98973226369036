import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdminBankDetailsSchema } from "../../../schemas/admin/AdiminBankDetails";
import { RHFCheckbox } from "../../../components/RFH/RFHcheckbox";
import Api from "../../../services/axios";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import toast from "react-hot-toast";
import { useBankDetailsList } from "../../../utils/reactQuery/adminBankDetaillist";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQueryClient } from "react-query";
export default function AdminBankDetailsComp() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64String, setBase64String] = useState("");
  const { list } = useBankDetailsList();
  const queryClient = useQueryClient();

  const defaultValues = {
    status: false,
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    actual_name: "",
    upi_id: "",
    qr_code: "",
  };

  const methods = useForm({
    resolver: yupResolver(AdminBankDetailsSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    const formDataWithQRCode = { ...formData, qr_code: base64String };
    console.log("form data", formDataWithQRCode);

    Api.post("/admin/add-admin-upi-details", formDataWithQRCode)
      .then((res) => {
        console.log("add admin upi details == ", res);
        toast.success(res.data.message);
        methods.reset();
        setSelectedFile("");
      })
      .catch((err) => {
        console.log("add admin upi details error == ", err);
        toast.error(err.response.data.error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size exceeds 3 MB. Please upload a smaller file.");
        return;
      }
      setSelectedFile(file);
      methods.setValue("qr_code", file); // Set the file into React Hook Form
      convertToBase64(file);
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.replace(/^data:.+;base64,/, "");
      setBase64String(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteUpi = (upiId) => {
    Api.post("/admin/delete-admin-upi-details", {
      upi_id: upiId,
    })
      .then((res) => {
        console.log("add admin upi details == ", res);
        queryClient.invalidateQueries({
          queryKey: ["adminBankDetailList"],
        });
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("add admin upi details error == ", err);
        toast.error("something went wrong");
      });
  };

  const handleEnableDisable = (upi, status) => {
    Api.post("/admin/update-admin-upi-details-status", {
      upi_id: upi,
      status: !status,
    })
      .then((res) => {
        console.log("enable disable == ", res);
        queryClient.invalidateQueries({
          queryKey: ["adminBankDetailList"],
        });
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("enable disable error ==  ", err);
        toast.error("something went wrong");
      });
  };
  const columns = [
    {
      field: "account_number",
      headerName: "Account Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "actual_name",
      headerName: "Actual Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "ifsc_code",
      headerName: "IFSC Code",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value ? "True" : "False"}</span>,
    },
    {
      field: "upi_id",
      headerName: "UPI ID",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack direction="row" mt={1} spacing={1}>
          <DeleteIcon
            sx={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDeleteUpi(value.value.upi)}
          />
          {value.value.status ? (
            <Button
              className={styles.withdrawBtn}
              onClick={() =>
                handleEnableDisable(value.value.upi, value.value.status)
              }>
              Disable
            </Button>
          ) : (
            <Button
              onClick={() =>
                handleEnableDisable(value.value.upi, value.value.status)
              }
              className={styles.depositBtn}>
              Enable
            </Button>
          )}
        </Stack>
      ),
    },
  ];
  return (
    <>
      <Box className={styles.container}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <RHFCheckbox name="status" />
                  <Typography className={styles.label}>Status</Typography>
                </Stack>
              </Grid>
              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <Typography className={styles.label}>
                    Account Number
                  </Typography>
                  <RHFTextField name="account_number" />
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <Typography className={styles.label}>Bank Name</Typography>
                  <RHFTextField name="bank_name" />
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <Typography className={styles.label}>UPI ID</Typography>
                  <RHFTextField name="upi_id" />
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <Typography className={styles.label}>IFSC Code</Typography>
                  <RHFTextField name="ifsc_code" />
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack className={styles.itemCont}>
                  <Typography className={styles.label}>Actual Name</Typography>
                  <RHFTextField name="actual_name" />
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Please upload QR code</Typography>
              <input
                accept="image/*" // You can change this to the file types you want
                style={{ display: "none" }}
                id="upload-button"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-button">
                <Button variant="contained" component="span">
                  Choose File
                </Button>
              </label>
              {selectedFile && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    File: {selectedFile.name}
                  </Typography>
                  {/* <Typography variant="body2">
                  Size: {selectedFile.size} bytes
                </Typography>
                <Typography variant="body2">
                  Base64: {base64String.slice(0, 100)}... (truncated)
                </Typography> */}
                </Box>
              )}
            </Box>
            <Stack justifyContent="end" alignItems="end">
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "fit-content" }}>
                Save
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      <Box p={1.3}>
        <Box className={styles.dataGridComp}>
          <TableHeader heading={"All Admin Details"} />
          <Box p={1} mt={1}>
            <DataGridComp
              columns={columns}
              initialRows={
                list
                  ? list.map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                        actions: { upi: ele.upi_id, status: ele.status },
                      };
                    })
                  : []
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
