import * as Yup from "yup";

export const AddBonusSchema = Yup.object().shape({
  first_deposit_amount: Yup.string().required(
    "first deposit amount is required"
  ),
  first_deposit_reward: Yup.string().required(
    "first deposit reward is required"
  ),
});
