import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { DateFormate } from "../../../utils/helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddAdminModal from "./addAdmin";
import { useSubAdminQuery } from "../../../utils/reactQuery/subAdmin/getSubAdminList";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import UpdateSubAdminModal from "./updateSubAdmin";
import ChangeSubAdminPassModal from "./changeSubadminPass";

export default function SubAdminComp() {
  const [addVip, setAddVip] = useState(false);
  const { list } = useSubAdminQuery();
  const [editStatus, setEditStatus] = useState(false);
  const [updatePassModal, setUpdatePassModal] = useState(false);
  const [selectedMblNo, setSelectedMblNo] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();

  const handleEditSubAdmin = (mblNo, status) => {
    setSelectedMblNo(mblNo);
    setSelectedStatus(status);
    setEditStatus(true);
  };

  const handleUpdatePassword = (mblNo) => {
    setSelectedMblNo(mblNo);
    setUpdatePassModal(true);
  };

  const columns = [
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{DateFormate(value.value)}</span>,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{DateFormate(value.value)}</span>,
    },
    {
      field: "status_active",
      headerName: "Status",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>{value.value == true ? "Active" : "Inactive"}</span>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>{value.value === "sub-admin" ? "Sub Admin" : "Admin"}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack direction="row" spacing={1} mt={1.5}>
          <EditIcon
            onClick={() =>
              handleEditSubAdmin(value.value.mblNo, value.value.status)
            }
          />
          <LockResetIcon
            onClick={() => handleUpdatePassword(value.value.mblNo)}
          />
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <Stack direction="row" className={styles.headerContainer}>
        <Typography className={styles.tableHead}>Admin / Sub Admin</Typography>
        <Button
          className={styles.btnClass}
          onClick={() => setAddVip(true)}
          variant="contained"
          color="primary">
          Add admin
        </Button>
      </Stack>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Admin / Sub Admin list"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns}
            initialRows={
              list
                ? list.map((ele, idx) => ({
                    id: idx,
                    actions: {
                      mblNo: ele.mobile_number,
                      status: ele.status_active,
                    },
                    ...ele,
                  }))
                : []
            }
          />
        </Box>
      </Box>
      {addVip && (
        <AddAdminModal openProp={addVip} handleClose={() => setAddVip(false)} />
      )}
      {editStatus && (
        <UpdateSubAdminModal
          openProp={editStatus}
          handleClose={() => setEditStatus(false)}
          mblNo={selectedMblNo}
          status={selectedStatus}
        />
      )}
      {updatePassModal && (
        <ChangeSubAdminPassModal
          openProp={updatePassModal}
          handleClose={() => setUpdatePassModal(false)}
          mblNo={selectedMblNo}
        />
      )}
    </Box>
  );
}
