import axios from "axios";
import Api from "../axios";

export const getGameHistory = async ({ gameId, limit, offset }) => {
  return await axios.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/color-prediction/getGameHistory?gameTypeId=${gameId}&limit=${limit}&offset=${
      offset * 10
    }`
  );
};
