import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";

import toast from "react-hot-toast";
import { useResponsive } from "../../../hooks/customUseResponsive";
import { AddVipSchema } from "../../../schemas/admin/AddVIP";
import Api from "../../../services/axios";
import { RHFCheckbox } from "../../RFH/RFHcheckbox";
import RHFTextField from "../../RFH/RFHtextfield";
import RFHDatePicker from "../../RFH/RFHDatePicker";
import { AcceptWithdraw } from "../../../schemas/admin/AcceptWithdraw";
import { RHFSelect } from "../../RFH/RFHselect";

export default function AcceptPop({ openProp, handleClose, id, refetch }) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    transaction_number: id,
  };
  const methods = useForm({
    resolver: yupResolver(AcceptWithdraw),
    defaultValues,
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    Api.post("/admin/complete-withdrawDeposit-request", formData)
      .then((res) => {
        console.log("response Accpet withdraw registration", res);
        toast.success(res.data.message);
        handleClose();
        refetch();
      })
      .catch((err) => {
        console.log("VIP error == ", err);
        toast.error(err.response.data.error);
      });
  };
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Accept Withdraw
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>
                  Transaction Number
                </Typography>
                <RHFTextField
                  name="transaction_number"
                  disabled
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography className={styles.label}>
                  User account detail
                </Typography>
                <RHFTextField
                  name="user_account_details"
                  InputProps={{
                    sx: {
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography className={styles.label}>
                  Admin account detail
                </Typography>
                <RHFTextField
                  name="admin_account_details"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography className={styles.label}>
                  Transaction Medium
                </Typography>
                {/* <RHFTextField
                  name="transaction_medium"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                /> */}
                <RHFSelect
                  name="transaction_medium"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}>
                  <MenuItem value="UPI">UPI</MenuItem>
                  <MenuItem value="bank">Bank transfer</MenuItem>
                </RHFSelect>
              </Box>
              <Box>
                <Typography className={styles.label}>UTR Number</Typography>
                <RHFTextField
                  name="utr_number"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={!openProp}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
