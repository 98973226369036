import * as Yup from "yup";

export const AmountSetting = Yup.object().shape({
  minimum_deposit_enabled: Yup.boolean(),
  minimum_deposit: Yup.number(),
  maximum_deposit_enabled: Yup.boolean(),
  maximum_deposit: Yup.number(),
  minimum_withdrawl_enabled: Yup.boolean(),
  minimum_withdrawl: Yup.number(),
  maximum_withdrawl_enabled: Yup.boolean(),
  maximum_withdrawl: Yup.number(),
  bet_commission_enabled: Yup.boolean(),
  bet_commission: Yup.number(),
  withdraw_commission_enabled: Yup.boolean(),
  withdraw_commission: Yup.number(),
  referral_commission_enabled: Yup.boolean(),
  referral_commission_percentage: Yup.number(),
  referral_amount: Yup.number(),
  reward_bet_amount_enabled: Yup.boolean(),
  reward_bet_amount: Yup.number(),
  reward_bonus_percentage_of_total_bet_amount: Yup.number(),
  daily_login_bet_count_enabled: Yup.boolean(),
  daily_login_bet_count: Yup.number(),
  reward_daily_login_reward_percentage: Yup.number(),
  children_add_bet_count_enabled: Yup.boolean(),
  children_add_bet_count: Yup.number(),
  children_add_reward: Yup.number(),
  consecutive_winning_count_enabled: Yup.boolean(),
  consecutive_winning_count: Yup.number(),
  consecutive_winning_amount: Yup.number(),
});
