import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FaceIcon from "@mui/icons-material/Face";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";
import walletIcon from "../../utils/images/wallet-svgrepo-com.svg";
import profileIcon from "../../utils/images/account-svgrepo-com.svg";
import referIcon from "../../utils/images/book-reference-svgrepo-com.svg";
import HomeIcon from "@mui/icons-material/Home";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Api from "../../services/axios";
import { DateFormate } from "../../utils/helper";
import toast from "react-hot-toast";
import TransactionComp from "../deposit/transactions";
import PaidIcon from "@mui/icons-material/Paid";
import LogoutIcon from "@mui/icons-material/Logout";
export default function ProfileComp() {
  const navigate = useNavigate();
  const [enteredAmount, setEnteredAmount] = useState("");
  const [fund, setFund] = useState("0");
  const [accountDetails, setAccountDetails] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    Api.get("/user/get-user-fund")
      .then((res) => {
        setFund(res.data.data.fund);
      })
      .catch((err) => {
        console.log("available fund err ==", err);
      });
    Api.get("/user/get-user")
      .then((res) => {
        console.log("user details == ", res.data.data.user_details);
        setAccountDetails(res.data.data.user_details);
      })
      .catch((err) => {
        console.log("available fund err ==", err);
      });
  }, []);

  const handleClick = (event) => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box className={styles.mainCont}>
      <Box className={styles.backCont}>
        <Stack className={styles.navCont}>
          <HomeIcon
            className={styles.leftIcon}
            onClick={() => navigate("/dashboard")}
          />
          <Typography className={styles.logo}>Profile</Typography>
          <LogoutIcon className={styles.profileIcon} onClick={handleClick} />
        </Stack>
        <Box className={styles.walletSection}>
          <Stack className={styles.walletBalanceCont}>
            <img src={profileIcon} alt="wallet" className={styles.walletIcon} />
            <Typography className={styles.balanceTxt}>
              Account Details
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} ml={3} mt={1}>
            <Typography width={90} fontWeight={550}>
              Mobile No.{" "}
            </Typography>
            <Typography>{accountDetails?.mobile_number}</Typography>
          </Stack>

          <Stack direction="row" spacing={2} ml={3}>
            <Typography width={90} fontWeight={550}>
              Name{" "}
            </Typography>
            <Typography>{accountDetails?.name}</Typography>
          </Stack>

          <Stack direction="row" spacing={2} ml={3}>
            <Typography width={90} fontWeight={550}>
              DOB{" "}
            </Typography>
            <Typography>
              {accountDetails?.date_of_birth
                ? DateFormate(accountDetails?.date_of_birth)
                : ""}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box className={styles.walletSection}>
        <Stack className={styles.walletBalanceCont}>
          <img src={walletIcon} alt="wallet" className={styles.walletIcon} />
          <Typography className={styles.balanceTxt}>
            Available balance
          </Typography>
        </Stack>
        <Typography className={styles.amount}>&#8377; {fund}</Typography>

        <Stack direction="row" spacing={2} mt={1}>
          <Button
            className={styles.withdrawBtn}
            onClick={() => navigate("/withdraw")}>
            Withdraw
          </Button>
          <Button
            className={styles.depositBtn}
            onClick={() => navigate("/deposit")}>
            Deposit
          </Button>
        </Stack>
      </Box>

      <Box className={styles.walletSection}>
        <Stack className={styles.walletBalanceCont}>
          <img src={referIcon} alt="wallet" className={styles.walletIcon} />
          <Typography className={styles.balanceTxt}>
            Reference Details
          </Typography>
        </Stack>

        <Typography m={1} ml={3}>
          Refer code. {accountDetails?.refer_code}
        </Typography>
        <Stack
          direction="row"
          ml={3}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            window.navigator.clipboard.writeText(
              `https://amargaming.com/registration?rfrcode=${accountDetails?.refer_code}`
            );
            toast.success("Link copied");
          }}>
          <Typography mr={1}>copy refer link </Typography>
          <ContentCopyIcon />
        </Stack>
      </Box>
      <Box className={styles.walletSection}>
        <Stack className={styles.walletBalanceCont}>
          <PaidIcon className={styles.walletIcon} />
          <Typography className={styles.balanceTxt}>
            Transaction Details
          </Typography>
        </Stack>
        <TransactionComp />
      </Box>
    </Box>
  );
}
