import * as Yup from "yup";

export const AddSubAdminSchema = Yup.object().shape({
  name: Yup.string().required("name is required").required("name is required"),
  mobile: Yup.string().required("mobile is required"),
  password: Yup.string().required("password is required"),
  date_of_birth: Yup.string().required("DOB is required"),
  status_active: Yup.boolean().required("status is required"),
  role: Yup.string().required("role is required"),
});
