import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FaceIcon from "@mui/icons-material/Face";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";
import CachedIcon from "@mui/icons-material/Cached";
import walletIcon from "../../utils/images/wallet-svgrepo-com.svg";
import HomeIcon from "@mui/icons-material/Home";
import { LoadingButton } from "@mui/lab";
import SquareIcon from "@mui/icons-material/Square";
import Api from "../../services/axios";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RFH/RFHtextfield";
import { yupResolver } from "@hookform/resolvers/yup";
import { addUserBankSchema } from "../../schemas/WithdrawSchema";
import toast from "react-hot-toast";
import LogoutIcon from "@mui/icons-material/Logout";

export default function WithdrawComp() {
  const navigate = useNavigate();
  const [enteredAmount, setEnteredAmount] = useState("");
  const [fund, setFund] = useState("0");
  const [accountDetails, setAccountDetails] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const methods = useForm({
    resolver: yupResolver(addUserBankSchema),
    defaultValues: {
      amount: "",
      utr_number: "",
    },
  });
  useEffect(() => {
    Api.get("/user/get-user-fund")
      .then((res) => {
        setFund(res.data.data.fund);
      })
      .catch((err) => {
        console.log("available fund err ==", err);
      });
    Api.get("/user/get-user-bank-details")
      .then((res) => {
        setAccountDetails(res.data.data);
        methods.setValue("account_number", res.data.data.account_number);
        methods.setValue("actual_name", res.data.data.actual_name);
        methods.setValue("bank_name", res.data.data.bank_name);
        methods.setValue("upi_id", res.data.data.upi_id);
        methods.setValue("ifsc_code", res.data.data.ifsc_code);
        console.log("get user bank details ", res);
      })
      .catch((err) => {
        console.log("get user bank ", err);
      });
  }, []);

  const onSubmit = (formData) => {
    console.log("form data == ", formData);
    Api.post("/user/add-user-bank-details", { ...formData })
      .then((res) => {
        console.log("add bank details ", res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("add bank details ", err);
      });
  };

  const handleWithdraw = () => {
    Api.post("/user/raise-payment-request", {
      transaction_type: "withdraw",
      transaction_amount: enteredAmount,
    })
      .then((res) => {
        console.log("withdraw response == ", res);
        toast.success(res.data.message);
        setEnteredAmount("");
      })
      .catch((err) => {
        console.log("withdraw error == ", err);
        toast.error(err?.response.data.error);
        setEnteredAmount("");
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={styles.mainCont}>
      <Box className={styles.backCont}>
        <Stack className={styles.navCont}>
          <HomeIcon
            className={styles.leftIcon}
            onClick={() => navigate("/dashboard")}
          />
          <Typography className={styles.logo}>Withdraw</Typography>
          <FaceIcon className={styles.profileIcon} onClick={handleClick} />
        </Stack>
        <Box className={styles.walletSection}>
          <Stack className={styles.walletBalanceCont}>
            <img src={walletIcon} alt="wallet" className={styles.walletIcon} />
            <Typography className={styles.balanceTxt}>
              Available balance
            </Typography>
          </Stack>
          <Typography className={styles.amount}>
            &#8377; {fund}
            <CachedIcon
              className={styles.reloadICon}
              onClick={() => window.location.reload()}
            />
          </Typography>
          {/* <Stack className={styles.btnCont}>
            <Typography className={styles.stars}>****</Typography>
            <Typography className={styles.stars}>****</Typography>
          </Stack> */}
        </Box>
      </Box>
      <Box className={styles.walletSection}>
        <TextField
          placeholder="Please enter the amount"
          variant="outlined"
          fullWidth
          value={enteredAmount}
          type="number"
          error={enteredAmount > fund}
          helperText={enteredAmount > fund ? "Insufficient balance" : ""}
          onChange={(e) => setEnteredAmount(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "40px",
              height: "40px",
              backgroundColor: "lightgray",
            },
            "& input::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "& input::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "& input[type=number]": {
              "-moz-appearance": "textfield", // Firefox
            },
          }}
        />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography>
            Withdrawable balance
            <span style={{ marginLeft: "5px" }}>&#8377;{fund}</span>
          </Typography>
          {/* <Button
            variant="outlined"
            sx={{ borderRadius: "10px", height: "20px" }}>
            All
          </Button> */}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}>
          <Typography>Withdrawable amount received</Typography>
          <Typography className={styles.balance}>
            &#8377;{enteredAmount}
          </Typography>
        </Stack>

        <LoadingButton
          disabled={enteredAmount < 1 || fund < enteredAmount}
          loading={false}
          className={styles.btnLogin}
          onClick={handleWithdraw}>
          Withdraw
        </LoadingButton>
      </Box>

      <Box className={styles.walletSection}>
        <Typography fontWeight={600}>Bank Details</Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={1} my={1} className={styles.form}>
              <RHFTextField
                name="account_number"
                placeholder="Account Number"
                label={accountDetails?.account_number ? "Account Number" : ""}
                value={accountDetails?.account_number || ""}
                onChange={(event) => {
                  setAccountDetails({
                    ...accountDetails,
                    account_number: event.target.value,
                  });
                  methods.setValue("account_number", event.target.value);
                }}
              />
              <RHFTextField
                name="bank_name"
                placeholder="Bank Name"
                label={accountDetails?.bank_name ? "Bank Name" : ""}
                value={accountDetails?.bank_name || ""}
                onChange={(event) => {
                  setAccountDetails({
                    ...accountDetails,
                    bank_name: event.target.value,
                  });
                  methods.setValue("bank_name", event.target.value);
                }}
              />
              <RHFTextField
                name="actual_name"
                placeholder="Enter Actual Name"
                label={accountDetails?.actual_name ? "Actual Name" : ""}
                value={accountDetails?.actual_name || ""}
                onChange={(event) => {
                  setAccountDetails({
                    ...accountDetails,
                    actual_name: event.target.value,
                  });
                  methods.setValue("actual_name", event.target.value);
                }}
              />
              <RHFTextField
                name="ifsc_code"
                placeholder="Enter IFSC code"
                label={accountDetails?.ifsc_code ? "IFSC code" : ""}
                value={accountDetails?.ifsc_code || ""}
                onChange={(event) => {
                  setAccountDetails({
                    ...accountDetails,
                    ifsc_code: event.target.value,
                  });
                  methods.setValue("ifsc_code", event.target.value);
                }}
              />
              <RHFTextField
                name="upi_id"
                placeholder="Enter UPI Id"
                label={accountDetails?.upi_id ? "UPI Id" : ""}
                value={accountDetails?.upi_id || ""}
                onChange={(event) => {
                  setAccountDetails({
                    ...accountDetails,
                    upi_id: event.target.value,
                  });
                  methods.setValue("upi_id", event.target.value);
                }}
              />
            </Stack>
            <LoadingButton
              loading={false}
              className={styles.btnLogin}
              type="submit"
              disabled={methods.watch("amount") > fund}>
              {accountDetails ? "Update" : "add"}
            </LoadingButton>
          </form>
        </FormProvider>
      </Box>

      <Box className={styles.contentCont}>
        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Need to bet <span className="red">₹0.00</span> to be able to withdraw
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Withdraw time <span className="red">₹0.00</span>
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Inday Remaining Withdrawal Times <span className="red">₹0.00</span>
        </Typography>

        <Typography className={styles.list}>
          Withdrawal amount range{" "}
          <span className="red">₹110.00-₹10,000,000.00</span>
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Please confirm your beneficial account information before withdrawing.
          If your information is incorrect, our company will not be liable for
          the amount of loss
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          If your beneficial information is incorrect, please contact customer
          service
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
          className={styles.menuitem}>
          <FaceIcon className={styles.menuIco} /> Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} className={styles.menuitem}>
          <LogoutIcon className={styles.menuIco} /> Log Out
        </MenuItem>
      </Menu>
    </Box>
  );
}
