import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import AddVipModal from "./AddVip";
import Api from "../../../services/axios";
import { DateFormate } from "../../../utils/helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditAmountSetting from "./editAmountSetting";

export default function VipRegistrationComp() {
  const [addVip, setAddVip] = useState(false);
  const [vipList, setVipList] = useState([]);
  const [amountSetting, setAmountSetting] = useState(false);
  const selectedVipMobile = useRef();

  useEffect(() => {
    Api.get("/admin/get-all-vip-user")
      .then((res) => {
        console.log("get all vip user == ", res);
        setVipList(
          res.data.data.map((ele) => {
            return { ...ele, id: ele.mobile_number };
          })
        );
      })
      .catch((err) => {
        console.log("err of get vip user == ", err);
      });
  }, []);

  const columns = [
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{DateFormate(value.value)}</span>,
    },
    {
      field: "parent_refer_code",
      headerName: "Parent Refer Code",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "refer_code",
      headerName: "Refer Code",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{DateFormate(value.value)}</span>,
    },
    {
      field: "vip",
      headerName: "VIP",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value ? "true" : "false"}</span>,
    },
    {
      field: "otp_verified",
      headerName: "OTP Verified",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value ? "true" : "false"}</span>,
    },
    {
      field: "actions",
      headerName: "Amount Settings",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Box onClick={() => handleVipAmountPop(value.value)}>
          <RemoveRedEyeIcon />
        </Box>
      ),
    },
  ];

  const handleVipAmountPop = (mobileNo) => {
    selectedVipMobile.current = mobileNo;
    setAmountSetting(true);
  };

  return (
    <Box>
      <Stack direction="row" className={styles.headerContainer}>
        <Typography className={styles.tableHead}>VIP registration</Typography>
        <Button
          className={styles.btnClass}
          onClick={() => setAddVip(true)}
          variant="contained"
          color="primary">
          Add VIP
        </Button>
      </Stack>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Admin coin"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns}
            initialRows={
              vipList
                ? vipList.map((ele, idx) => {
                    return {
                      id: idx,
                      actions: ele.mobile_number,
                      ...ele,
                    };
                  })
                : []
            }
          />
        </Box>
      </Box>
      {addVip && (
        <AddVipModal openProp={addVip} handleClose={() => setAddVip(false)} />
      )}
      {amountSetting && (
        <EditAmountSetting
          openPop={amountSetting}
          id={selectedVipMobile.current}
          handleClose={() => setAmountSetting(false)}
        />
      )}
    </Box>
  );
}
