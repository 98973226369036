import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar/index";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "./style.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FaceIcon from "@mui/icons-material/Face";
import walletIcon from "../../utils/images/wallet-svgrepo-com.svg";
import CachedIcon from "@mui/icons-material/Cached";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import OneMin from "./1min";
import ThreeMin from "./3min";
import FiveMin from "./5min";
import TenMin from "./10min";
import { useNavigate } from "react-router";
import axios from "axios";
import Api from "../../services/axios";
import LogoutIcon from "@mui/icons-material/Logout";
import { useUserFund } from "../../utils/reactQuery/colorGame/userFund";

export default function ColorGameComp() {
  const [value, setValue] = React.useState("1min");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [gameDetails, setGameDetails] = useState();
  const { userFund, refetch } = useUserFund();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/color-prediction/getGamesTypeList?gameId=1`
      )
      .then((res) => {
        console.log("response game details === ", res);
        setGameDetails(res.data.data.gameDetails);
      })
      .catch((err) => {
        console.log("err game details === ", err);
      });
  }, []);

  return (
    <Box className={styles.mainCont}>
      <Box className={styles.backCont}>
        <Stack className={styles.navCont}>
          <KeyboardArrowLeftIcon
            className={styles.leftIcon}
            onClick={() => navigate("/dashboard")}
          />
          <Typography className={styles.logo}>AmarGaming</Typography>
          <FaceIcon
            className={styles.profileIcon}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          />
        </Stack>
        <Box className={styles.walletSection}>
          <Typography className={styles.amount}>
            &#8377; {userFund?.fund ? Math.round(userFund?.fund) : "0"}
            <CachedIcon
              className={styles.reloadICon}
              onClick={() => refetch()}
            />
          </Typography>
          <Stack className={styles.walletBalanceCont}>
            <img src={walletIcon} alt="wallet" className={styles.walletIcon} />
            <Typography className={styles.balanceTxt}>
              Wallet balance
            </Typography>
          </Stack>
          <Stack className={styles.btnCont}>
            <Button
              className={styles.withdrawBtn}
              onClick={() => navigate("/withdraw")}>
              Withdraw
            </Button>
            <Button
              className={styles.depositBtn}
              onClick={() => navigate("/deposit")}>
              Deposit
            </Button>
          </Stack>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example"
          className={styles.tabs}>
          <Tab
            className={`${styles.tab} ${
              value === "1min" ? styles.activeTab : ""
            }`}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "red !important",
              },
            }}
            icon={<AvTimerIcon />}
            label="Win Go 1Min"
            value="1min"
          />
          <Tab
            className={`${styles.tab} ${
              value === "3min" ? styles.activeTab : ""
            }`}
            icon={<AvTimerIcon />}
            label="Win Go 3Min"
            value="3min"
          />
          <Tab
            className={`${styles.tab} ${
              value === "5min" ? styles.activeTab : ""
            }`}
            icon={<AvTimerIcon />}
            label="Win Go 5Min"
            value="5min"
          />
          <Tab
            className={`${styles.tab} ${
              value === "10min" ? styles.activeTab : ""
            }`}
            icon={<AvTimerIcon />}
            label="Win Go 10Min"
            value="10min"
          />
        </Tabs>
      </Box>
      {value === "1min" ? (
        <OneMin id={101} />
      ) : value === "3min" ? (
        <ThreeMin id={102} />
      ) : value === "5min" ? (
        <FiveMin id={103} />
      ) : (
        <TenMin id={104} />
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem
          onClick={() => {
            navigate("/profile");
            setAnchorEl(null);
          }}
          className={styles.menuitem}>
          <FaceIcon className={styles.menuIco} /> Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
          className={styles.menuitem}>
          <LogoutIcon className={styles.menuIco} /> Log Out
        </MenuItem>
      </Menu>
    </Box>
  );
}
