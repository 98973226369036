import axios from "axios";
import React, { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataGridComp from "../../components/DataGrid/DataGrid";

export default function BankDetailsComp({ gameId }) {
  const [limitState, setLimit] = useState(10);
  const [offsetState, setOfffset] = useState(0);
  const [history, setHistory] = useState([{}]);
  const mobileNUmber = localStorage.getItem("user");
  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/user/getUserBankTransaction?mobile_number=${mobileNUmber}&limit=${limitState}&offset=${
          offsetState * 10
        }`
      )
      .then((res) => {
        console.log("bank response == ", res);
        setHistory(res.data.data);
      })
      .catch((err) => {
        console.log("bank component == ", err);
      });
  }, [limitState, offsetState]);

  const columns = [
    {
      field: "col1",
      headerName: "Medium",
      minWidth: 70,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Type",
      minWidth: 70,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Amount",
      minWidth: 90,
      flex: 0.5,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Date",
      minWidth: 120,
      flex: 0.6,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];
  return (
    <Box mt={1}>
      {/* {history.map((ele) => (
        <h1>{ele.color}</h1>
      ))} */}
      <DataGridComp
        columns={columns}
        borderEnable={false}
        initialRows={
          history
            ? history.map((ele, idx) => {
                return {
                  id: idx,
                  col1: ele.transaction_medium,
                  col2: ele.transaction_type,
                  col3: ele.transaction_amount,
                  col4: ele.transaction_number,
                  col5: ele.transaction_date?.slice(0, 10),
                };
              })
            : []
        }
        footer={true}
      />
      <Stack direction="row" sx={{ width: "100%", justifyContent: "center" }}>
        <ArrowBackIcon
          onClick={() => setOfffset((prev) => prev - 1)}
          sx={{ opacity: offsetState <= 0 ? 0 : 1 }}
        />
        <Typography>{offsetState}</Typography>
        <ArrowForwardIcon onClick={() => setOfffset((prev) => prev + 1)} />
      </Stack>
    </Box>
  );
}
