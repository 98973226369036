import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import Api from "../../../services/axios";

export default function WithdrawDepositComp() {
  const [completedList, setCompletedList] = useState();
  const [pendingList, setPendingList] = useState();

  const columns = [
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>
          {value.value.slice(0, 10)} -- {new Date(value.value).getHours()}
          {":"}
          {new Date(value.value).getMinutes()}
        </span>
      ),
    },
    {
      field: "transaction_number",
      headerName: "Transaction number",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "transaction_amount",
      headerName: "Transaction Amount",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "false_transaction_status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>{value.value == true ? "Rejected" : "Accepted"}</span>
      ),
    },
  ];

  useEffect(() => {
    Api.post("/admin/get-all-withdrawal-deposit-requests-by-status", {
      transaction_completed: true,
    })
      .then((res) => {
        console.log("completed withdraw == ", res.data.data.data);
        setCompletedList(res.data.data.data);
      })
      .catch((err) => {
        console.log("completed withdraw error == ", err);
      });

    Api.post("/admin/get-all-withdrawal-deposit-requests-by-status", {
      transaction_completed: false,
    })
      .then((res) => {
        console.log("completed withdraw == ", res.data.data.data);
        setPendingList(res.data.data.data);
      })
      .catch((err) => {
        console.log("completed withdraw error == ", err);
      });
  }, []);
  return (
    <Box>
      <Typography className={styles.heading}>
        Withdraw Deposit Dashboard
      </Typography>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Completed Withdraw List"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns}
            initialRows={
              completedList
                ? completedList
                    .filter((ele) => ele.transaction_type != "deposit")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Pending Withdraw List"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns.filter(
              (ele) => ele.field != "false_transaction_status"
            )}
            initialRows={
              pendingList
                ? pendingList
                    .filter((ele) => ele.transaction_type != "deposit")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Completed Deposit List"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns}
            initialRows={
              completedList
                ? completedList
                    .filter((ele) => ele.transaction_type == "deposit")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Pending Deposit List"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns.filter(
              (ele) => ele.field != "false_transaction_status"
            )}
            initialRows={
              pendingList
                ? pendingList
                    .filter((ele) => ele.transaction_type == "deposit")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
