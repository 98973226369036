import { useQuery } from "react-query";
import { getCatpcha } from "../../services/catpcha";

export function useCaptcha(id) {
  const {
    isLoading,
    data: captcha,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["captcha"],
    queryFn: () => getCatpcha(id),
  });

  return { isLoading, error, captcha, isError, refetch };
}
