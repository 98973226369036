import {
    Box,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import LoadingButton from "@mui/lab/LoadingButton";
  import styles from "./styles.module.css";
  import RHFTextField from "../../../components/RFH/RFHtextfield";
  import { Form, FormProvider, useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { ForgotSchema, LoginSchema, resetSchema } from "../../../schemas/auth/loginSchema";
  import { useNavigate } from "react-router";
  import { useState } from "react";
  import axios from "axios";
  import toast from "react-hot-toast";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
import RFHDatePicker from "../../../components/RFH/RFHDatePicker";
  
  export default function ResetPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  
    const methods = useForm({
      resolver: yupResolver(resetSchema),
    });
  
    const { handleSubmit } = methods;
  
    const onSubmit = (data) => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/reset-password-user`, { 
            token: localStorage.getItem('token'),
            new_password: data.password
         })
        .then((res) => {
          console.log("response", res);
        //   localStorage.setItem("user", data.mobile);
          localStorage.setItem("token", res.data.data.token);
          toast.success(res.data.message);
          navigate("/login");
        })
        .catch((err) => {
          console.log("error", err);
        //   toast.error(err.response.data.error);
          setLoading(false);
        });
    };
  
    return (
      <Stack className={styles.mainContainer}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box className={styles.form}>
              <Typography className={styles.loginTxt}>Reset Password</Typography>
              <Box className={styles.textfieldCont}>
                <Typography className={styles.labels}>New Password</Typography>
                <RHFTextField type name="password"/>
              </Box>
  
              <Box className={styles.textfieldCont}>
              <Typography className={styles.labels}>Confirm Password</Typography>
              <RHFTextField type name="passwordConfirmation"/>
              </Box>

              <Box>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  className={styles.btnLogin}>
                  Reset Password
                </LoadingButton>
              </Box>
  
              {/* <Typography className={styles.doNotAcc}>
                Don't have an account?{" "}
                <span
                  className={styles.signupText}
                  onClick={() => navigate("/registration")}>
                  Sign Up
                </span>
              </Typography> */}
  
              <Typography
                className={styles.gotoHome}
                onClick={() => navigate("/")}>
                Go To Home
              </Typography>
            </Box>
          </form>
        </FormProvider>
      </Stack>
    );
  }
  