import { Controller, useFormContext } from 'react-hook-form';

import {TextField} from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFTextField({ name , ...other}) {
  const { control } = useFormContext();
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          fullWidth
          variant='standard'
          error={!!error}
          helperText={error ? error?.message : ''}
          sx={{
            "& .MuiInputBase-root::before": {
              borderBottom: "1px solid #fff",
              paddingLeft: '5px'
            },
            "& .MuiInputBase-input": {
              backgroundColor: "#fff !important",
              paddingLeft: '5px'
            },'& .Mui-focused':{
              backgroundColor: '#fff'
            },"& .MuiInput-root::after": {
                borderBottom: "2px solid #fff !important",
              },"& .Mui-focused":{
                backgroundColor: '#fff !important'
              }
          }}
          {...field}
        {...other}
        />
      )}
    />
  );
}
