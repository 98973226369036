import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import { AddVipSchema } from "../../../../schemas/admin/AddVIP.js";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFCheckbox } from "../../../../components/RFH/RFHcheckbox.js";
import RFHDatePicker from "../../../../components/RFH/RFHDatePicker.js";
import Api from "../../../../services/axios.js";
import toast from "react-hot-toast";
import { AddSubAdminSchema } from "../../../../schemas/admin/AddSubAdmin.js";
import { useQueryClient } from "react-query";
import { AddBonusSchema } from "../../../../schemas/admin/AddBonus.js";

export default function AddBonusModal({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");
  const queryCient = useQueryClient();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {};
  const methods = useForm({
    resolver: yupResolver(AddBonusSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    Api.post("/admin/add-first-deposit-bonus", formData)
      .then((res) => {
        console.log("add first deposit bonus ", res);
        toast.success(res.data.message);
        queryCient.invalidateQueries({
          queryKey: ["bonusList"],
        });
        handleClose();
      })
      .catch((err) => {
        console.log("VIP error == ", err);
        toast.error(err.response.data.error);
      });
  };
  console.log("errors == ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Bonus</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>
                  First deposit amount
                </Typography>
                <RHFTextField
                  name="first_deposit_amount"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography className={styles.label}>
                  First deposit reward
                </Typography>
                <RHFTextField
                  name="first_deposit_reward"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                />
              </Box>

              <Stack alignItems="end">
                <LoadingButton
                  loading={!openProp}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
