import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./styles.module.css";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import { Form, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../../../schemas/auth/loginSchema";
import { useNavigate } from "react-router";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const defaultValues = {
    mobile: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, { ...data })
      .then((res) => {
        console.log("response", res);
        localStorage.setItem("user", data.mobile);
        localStorage.setItem("bonus_modal", true);
        localStorage.setItem("token", res.data.data.token);
        toast.success(res.data.message);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <Stack className={styles.mainContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className={styles.form}>
            <Typography className={styles.loginTxt}>Login</Typography>
            <Box className={styles.textfieldCont}>
              <RHFTextField type name="mobile" placeholder="Phone Number" />
            </Box>

            <Box className={styles.textfieldCont}>
              <RHFTextField
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="password"
              />
              <Box
                className={styles.eyeCont}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {showPassword ? (
                  <VisibilityOff sx={{ color: "grey" }} />
                ) : (
                  <Visibility sx={{ color: "grey" }} />
                )}
              </Box>
            </Box>

            <Stack className={styles.rememberCont}>
              <Stack className={styles.checkboxCont}>
                <input
                  type="checkbox"
                  style={{ width: "10px !important", color: "#fff" }}
                />
                <Typography className={styles.rememberTxt}>
                  Remember me
                </Typography>
              </Stack>

              <Typography
                className={styles.forgotTxt}
                onClick={() => navigate("/forgotpassword")}>
                Forgot Password
              </Typography>
            </Stack>

            <Box>
              <LoadingButton
                loading={loading}
                type="submit"
                className={styles.btnLogin}>
                Log in
              </LoadingButton>
            </Box>

            <Typography className={styles.doNotAcc}>
              Don't have an account?{" "}
              <span
                className={styles.signupText}
                onClick={() => navigate("/registration")}>
                Sign Up
              </span>
            </Typography>

            <Typography
              className={styles.gotoHome}
              onClick={() => navigate("/")}>
              Go To Home
            </Typography>
          </Box>
        </form>
      </FormProvider>
    </Stack>
  );
}
