import { useQuery } from "react-query";
import { getSubAdminList } from "../../../services/subadmin/subAdminList";

export function useSubAdminQuery() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["subAdminList"],
    queryFn: () => getSubAdminList(),
  });

  const list = data?.data?.data;

  return { isLoading, error, list, isError, refetch };
}
