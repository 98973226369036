import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./styles.module.css";
import { useResponsive } from "../../hooks/customUseResponsive";

function DataGridComp({
  initialRows,
  columns,
  total,
  headerBgColor,
  columnGroupingModel,
  footer,
  rightBorder,
  pageSize,
  borderEnable
}) {
  const [rows, setRows] = useState([""]);
  const [selectedRows, setSelectedRows] = useState([]);
  const isMobile = useResponsive("down", "sm");

  const handleSelectionChange = (selectionModel) => {
    const newSelectedRow = selectionModel[0];
    if (newSelectedRow != null && !selectedRows.includes(newSelectedRow)) {
      setSelectedRows([...selectedRows, newSelectedRow]);
      setRows(
        rows.map((row) =>
          row.id === newSelectedRow ? { ...row, disabled: true } : row
        )
      );
    }
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row.disabled": {
              pointerEvents: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              // borderRight: "1px solid rgba(224, 224, 224, 1)",
              fontSize: isMobile ? "10px !important" : "14px !important",
              fontWeight: "400 !important",
              color: "#455F69",
              borderTop: '0px !important'
            },
            "& .MuiDataGrid-columnHeader": {
              height: isMobile ? "30px !important" : "48px !important",
              backgroundColor: "#4c4cad !important",
              fontSize: isMobile ? "12px" : "",
              color: "#fff !important",
              paddingLeft: "0px",
              paddingRight: "0px",
              // borderBottom: "1px solid #CED4DA",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              height: isMobile ? "30px !important" : "48px !important",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: isMobile ? "400 !important" : "500 !important",
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-columnGroupHeader": {
              backgroundColor: "#4c4cad !important",
              color: "#fff",
              outline: "none",
              fontSize: isMobile ? "10px" : "20px",
            },
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: isMobile ? "5px" : "10px",
              margin: isMobile ? "20px 5px !important" : "",
            },

            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainerContent":
              {
                margin: "auto !important",
                paddingLeft: "0px",
              },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
              {
                backgroundColor: "#F5F8FC",
                height: isMobile ? "50px !important" : "100px !important",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-columnHeader--filledGroup": {
              height: isMobile ? "150px !important" : "100px !important",
              maxHeight: isMobile ? "50px !important" : "100px",
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitle":
              {
                fontWeight: "700 !important",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-filler": {
              height: "0px !important",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: isMobile ? "10px !important" : "",
            },
            "& .MuiDataGrid-scrollbarFiller": {
              display: "none !important",
            },
            "& .MuiDataGrid-main": {
              border: borderEnable===false ? borderEnable : "1px solid #CED4DA",
              borderRadius: "4px",
              // overflowX: 'auto'
            },
            "& .MuiDataGrid-footerContainer": {
              border: "none", // Ensure the footer has no border
            },
            "& .MuiDataGrid-overlayWrapper": {
              top: isMobile ? "0px !important" : "",
            },
          },
        },
      },
    },
  });

  const CustomNoRowsOverlay = () => {
    return (
      <Stack className={styles.dataNotFound}>
        <Typography variant="subtitle1">No data found</Typography>
      </Stack>
    );
  };

  return (
    <Box className={styles.container}>
      <ThemeProvider theme={theme}>
        <Box
          style={{
            // overflowX: "auto",
          }}>
          <DataGrid
            rows={initialRows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick={true}
            onSelectionModelChange={(newSelection) =>
              handleSelectionChange(newSelection)
            }
            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            selectionModel={selectedRows}
            getRowClassName={(params) =>
              params.row.disabled ? "MuiDataGrid-row disabled" : ""
            }
            initialState={{
              pagination: { paginationModel: { pageSize: pageSize?pageSize:10 } },
            }}
            columnGroupingModel={
              columnGroupingModel ? columnGroupingModel : null
            }
            autoHeight
            disableExtendRowFullWidth
            checkboxSelection={false}
            hideFooterSelectedRowCount
            showCellVerticalBorder={borderEnable===false ? borderEnable :true}
            showColumnVerticalBorder={borderEnable===false ? borderEnable :true}
            hideFooter={footer}
            sx={{
              border: 0,
            }}
          />
          {/* <DataGrid
                columns={columns}
                rows={initialRows}
                disableColumnMenu
                getRowClassName={(params) =>
                  params.row.disabled ? "MuiDataGrid-row disabled" : ""
                }
                initialState={{
                  pagination: { paginationModel: { pageSize: 99 } },
                }}
                columnGroupingModel={
                  columnGroupingModel ? columnGroupingModel : null
                }
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                autoHeight
                sx={{
                  border: 0,
                  "& .MuiDataGrid-main": {
                    border: "1px solid #CED4DA",
                    borderRadius: "4px",
                    // pointerEvents: 'none',
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize:"14px !important",
                    fontWeight: "400 !important",
                    color: "#455F69",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    height:  "48px !important",
                    backgroundColor: "#F5F8FC",
                    color: "#455F69",
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    borderBottom: "1px solid #CED4DA",
                  },
                  "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
                    {
                      backgroundColor: "#F5F8FC",
                      height: "100px !important",
                      textWrap: "wrap",
                    },
                }}
              /> */}
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default DataGridComp;
